import React, { useEffect, useState, useRef } from "react"
import "./emailtemplate.scss"
import ShapeLogo from "../images/shape2.svg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TickSuccess from "../images/success_message_icon.svg"
import ContentEditable from "react-contenteditable"
import moment from "moment"

const EmailTemplate = ({ location }) => {
  const emailref = useRef(null)
  // const location = useLocation()
  const params = new URLSearchParams(location.search)
  const name = params.get("name")
  const deadLine = params.get("deadline")
  const id = params.get("id")
  const isSSO = params.get("sso")
  const [emailText, setEmailText] = useState("")
  const [textCopied, setTextCopied] = useState(false)
  const { templates } = require("../data/contact/emailTemplates.json")
  const emptyNamePlaceholder = "none"

  useEffect(() => {
    if (id && templates[id] && name) {
      let updatedText = templates[id]
        .replace(
          `[name]`,
          name.toLowerCase() === emptyNamePlaceholder ? "" : name
        )
        .replace(
          "[deadline]",
          `${moment(deadLine, "Do MMMM YYYY hh:mm:ss A")
            .local()
            .format("HH:mm A")} ${moment(
            deadLine,
            "Do MMMM YYYY hh:mm:ss A"
          ).format("dddd, MMMM Do, YYYY")}`
        )

      if (isSSO === "true") {
        updatedText = updatedText.replace(
          'Sign up <a href="https://app.shapepowered.com/signup" style="cursor: pointer;">here</a> to get started.',
          `Sign in <a href="https://app.shapepowered.com/login">here</a> to get started with your Single Sign On (SSO) company login.`
        )
      }

      setEmailText(updatedText)
    }
  }, [id, name, deadLine, isSSO, templates])

  const handleClick = (e) => {
    const target = e.target
    if (target.tagName === "A") {
      e.preventDefault() // Prevent default link behavior
      const href = target.getAttribute("href")
      window.location.href = href // Manually redirect to the URL
    }
  }

  return (
    <Layout>
      <SEO title="Email Template" />

      <div className={"mainEmailTemplate"}>
        <div className={"containerEmailTemplate"}>
          <img src={ShapeLogo} className={"logoEmail"} alt="SHAPE Logo Email" />
          <h2>Edit and copy the email template below</h2>
          <ContentEditable
            id={"email-text"}
            ref={emailref}
            className={"textarea"}
            contentEditable="true"
            onClick={handleClick}
            onChange={(e) => {
              setEmailText(e.target.value)
              setTextCopied(false)
            }}
            html={emailText}
            disabled={false}
          />
          {textCopied && (
            <div className={"copytext"}>
              <img src={TickSuccess} alt="Success" />
              <span>Email copied to your clipboard </span>
            </div>
          )}
          <button
            onClick={() => {
              const emailSelectopnArea = emailref.current["el"]["current"]
              window.getSelection().selectAllChildren(emailSelectopnArea)
              document.execCommand("copy")
              setTextCopied(true)
            }}
          >
            Copy email template
          </button>
        </div>
      </div>
    </Layout>
  )
}

export default EmailTemplate
